import { CollapsableEntity } from '../../types/CollapsableFilterTypes';
import {
  setProjectsConfigSelectedYear,
  setProjectsShownAmount,
  clearProjects,
  setProjectsConfigSkip,
} from '../../features/state/slices/homeSlice';
import { useAppDispatch, useAppSelector } from '../../features/state/hooks';
import { HOME_PROJECTS_LOADING_STEP } from '../../constants/home';

const NonCollapsableFilterPseudoGroup = ({
  filterGroupData,
  listHeader,
}: {
  filterGroupData: Array<CollapsableEntity>;
  listHeader: string;
}) => {
  const dispatch = useAppDispatch();
  const { selectedYear } = useAppSelector((state) => state.home.value.projectsConfig);
  return (
    <div className="aside-filter-section-group">
      <span className="aside-filter-section-group-header">{listHeader}</span>
      <div className="aside-filter-section-group-content">
        {filterGroupData.map((section, idx) => (
          <div className="aside-filter-section-container" key={section.name + idx}>
            <div
              onClick={() => {
                if (section.name === selectedYear?.toString()) {
                  // Unselect the filter
                  dispatch(setProjectsShownAmount(HOME_PROJECTS_LOADING_STEP));
                  dispatch(clearProjects());
                  dispatch(setProjectsConfigSelectedYear(null));
                  dispatch(setProjectsConfigSkip(0));
                  return;
                }
                dispatch(setProjectsShownAmount(HOME_PROJECTS_LOADING_STEP));
                dispatch(clearProjects());
                dispatch(setProjectsConfigSelectedYear(section.name));
                dispatch(setProjectsConfigSkip(0));
              }}
              className={`aside-filter-section-name${
                section.name === selectedYear?.toString() ? ' selected' : ''
              }`}>
              {section.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NonCollapsableFilterPseudoGroup;
