import { useState } from 'react';
import NotificationsList from '../components/common/NotificationsList';

import TaskIcon from '../assets/icons/task_icon.svg';
import DocumentIcon from '../assets/icons/document_icon.svg';
import ApprovedIcon from '../assets/icons/approved.svg';
import LateIcon from '../assets/icons/late.svg';
import InfoIcon from '../assets/icons/info_grey.svg';

import { getAlphabetPosition, getInitials } from '../utils/naming';
import { useParams } from 'react-router-dom';
import { STATUS_TYPES } from '../constants/notifications';
import { toggleNotificationAcknowledge } from '../api/endpoints/notifications';
import { setIsRead } from '../features/state/slices/notificationsSlice';
import { useApi } from '../api/ApiContext';
import { useAppDispatch, useAppSelector } from '../features/state/hooks';

const Notifications = () => {
  // Retrieve query param id
  const { id } = useParams();
  const { apiInstance } = useApi();

  const dispatch = useAppDispatch();
  const [selectedNotificationId, setSelectedNotificationId] = useState(id);
  const notifications = useAppSelector((state) => state.notifications.value.all);
  const currentUser = useAppSelector((state) => state.currentUser.value);

  const selectedNotification = notifications.find((n) => n.id === selectedNotificationId);

  const alphabetClass =
    selectedNotification &&
    `alphabet-${getAlphabetPosition(selectedNotification.userName.charAt(0))}`;
  const statusType =
    selectedNotification && selectedNotification.status !== STATUS_TYPES.NONE
      ? selectedNotification.status === STATUS_TYPES.APPROVED
        ? 'approved'
        : 'late'
      : '';
  const statusIcon =
    selectedNotification && selectedNotification.status !== STATUS_TYPES.NONE
      ? selectedNotification.status === STATUS_TYPES.APPROVED
        ? ApprovedIcon
        : LateIcon
      : InfoIcon;

  const onNotificationClick = async (notificationId: string) => {
    const notification = notifications.find((n) => n.id === notificationId);
    if (!notification?.isRead) {
      //TODO: Remove when test notifications are removed
      const isTestNotification = !isNaN(notificationId as any);
      let isRead;
      if (isTestNotification) {
        isRead = !notifications.find((n) => n.id === notificationId)?.isRead;
      } else {
        isRead = await toggleNotificationAcknowledge(
          apiInstance,
          notificationId,
          currentUser?.id || '',
        ).then((resp) => resp.responseAsJson.isAcknowledged);
      }
      dispatch(setIsRead({ notificationId, isRead }));
    }
    setSelectedNotificationId(notificationId);
  };

  return (
    <div className="notifications-container">
      <aside className="list">
        <NotificationsList
          selectedNotificationId={selectedNotificationId}
          onNotificationClick={(id) => {
            onNotificationClick(id);
          }}
        />
      </aside>
      {selectedNotification && (
        <section className="notification">
          <section className="header">
            <div className="title">
              <img src={TaskIcon} alt="Task" />
              <span>Notification</span>
            </div>
            <div className="details">
              <div className="detail">
                <span className="title">Project ID</span>
                <span className="info">{selectedNotification?.projectId}</span>
              </div>
              <div className="detail">
                <span className="title">Customer Name</span>
                <span className="info">{selectedNotification?.customerName}</span>
              </div>
              <div className="detail">
                <span className="title">Region</span>
                <span className="info">{selectedNotification?.region}</span>
              </div>
              <div className="detail">
                <span className="title">Quote Count</span>
                <span className="info">{selectedNotification?.quoteCount}</span>
              </div>
              <div className="detail">
                <span className="title">Job Count</span>
                <span className="info">{selectedNotification?.jobCount}</span>
              </div>
              {/* <div className="detail">
                <span className="title">Job Stage</span>
                <div>
                  <span className="info job-stage">{selectedNotification?.jobStage}</span>
                </div>
              </div> */}
            </div>
          </section>
          <div className="main-content">
            <section className="body">
              <div className="sender">
                <div className={`sender-image ${alphabetClass} ${statusType}`}>
                  {selectedNotification?.isUserMessage ? (
                    <span className="initials">{getInitials(selectedNotification?.userName)}</span>
                  ) : (
                    <img className="nonuser-icon" src={statusIcon} alt="Sender" />
                  )}
                </div>
                <div className="sender-details">
                  <span className="name">{selectedNotification?.userName}</span>
                  <span className="time">{selectedNotification?.timeStamp}</span>
                </div>
              </div>
              <p>{selectedNotification?.description}</p>
            </section>
            {selectedNotification?.hasAttachment && (
              <section className="footer">
                <div className="attachment">
                  <div className="icon-container">
                    <img src={DocumentIcon} alt="Document" />
                  </div>
                  <div className="details">
                    <span className="title">Attachment title here</span>
                    <span className="type-size">XLSX - 14.56 KB</span>
                  </div>
                </div>
              </section>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default Notifications;
