import {
  useRef,
  // useEffect, useState
} from 'react';
// import { useWindowSize } from '../../utils/hooks/useWindowSize';

const ProjectIndexProgressBar = ({
  indexName,
  indexValue,
}: {
  indexName: string;
  indexValue: number;
}) => {
  // const [loadingPopupContent, setLoadingPopupContent] = useState(true);
  const projectCardRef = useRef<HTMLDivElement>(null);
  // const { width } = useWindowSize();
  // const [shouldDisplayPopUpToLeft, setShouldDisplayPopUpToLeft] = useState(false);

  // useEffect(() => {
  //   const triggerPoint = width * 0.8;
  //   setShouldDisplayPopUpToLeft(
  //     triggerPoint <= projectCardRef.current!.getBoundingClientRect().right,
  //   );
  // }, [width]);

  const onHover = () => {
    // setLoadingPopupContent(true);
    // setTimeout(() => {
    //   setLoadingPopupContent(false);
    // }, 4000); // Temporary set timeout to simulate calling an API endpoint
  };

  return (
    <div ref={projectCardRef} className="project-card-index-container" onMouseEnter={onHover}>
      <div className="progress-bar">
        <div className={`progress progress-${indexName.toLowerCase()} width-${indexValue}`} />
      </div>
      <span className="project-index-name">{indexName}</span>

      {/* <div
        className={`project-card-pop-up-container ${shouldDisplayPopUpToLeft ? 'left-popup' : ''}`}>
        <h1 className="title">Project Initiation</h1>
        <hr />
        <div className={`content ${loadingPopupContent ? 'loading' : ''}`}>
          {loadingPopupContent ? <span className="loader" /> : <>Content loaded</>}
        </div>
      </div> */}
    </div>
  );
};

export default ProjectIndexProgressBar;
