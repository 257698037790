import { SearchCriteria } from '../../features/state/slices/searchSlice';
import { ApiBase, ApiResponse } from '../apiBase';

export const sortTypes = {
  LAST_MOD_DATE: 'LastModDate',
  CUSTOMER_NAME: 'CustomerName',
};

/**
 * Get home projects data.
 */
export const getProjects = (
  apiInstance: ApiBase,
  amount: number = 5,
  skip: number = 0,
  sortType: string = sortTypes.LAST_MOD_DATE,
  reverseOrder: boolean = false,
  searchValue: string = '',
  regionSearchValue: string | null = '',
  countrySearchValue: string = '',
  yearSearchValue: number | null,
): Promise<ApiResponse> => {
  return apiInstance.get(
    `Projects?Amount=${amount}&Skip=${skip}&SortType=${sortType}&ReverseOrder=${reverseOrder}${
      (searchValue ? `&ProjectSearchValue=${searchValue}` : '') +
      (regionSearchValue ? `&RegionSearchValue=${regionSearchValue}` : '') +
      (countrySearchValue ? `&CountrySearchValue=${countrySearchValue}` : '') +
      (yearSearchValue ? `&YearSearchValue=${yearSearchValue}` : '')
    }`,
  );
};

/**
 * Get project stages.
 */
export const getProjectStages = (apiInstance: ApiBase, projectId: string): Promise<ApiResponse> => {
  return apiInstance.get(`Projects/Stages?projectId=${projectId}`);
};

/**
 * Search for projects.
 */
export const searchProjects = (
  apiInstance: ApiBase,
  searchCriteria: SearchCriteria,
): Promise<ApiResponse> => {
  const searchParams = {
    projectTitle: searchCriteria.projectTitle ? searchCriteria.projectTitle : undefined,
    projectId: searchCriteria.projectId ? searchCriteria.projectId : undefined,
    region: searchCriteria.region ? searchCriteria.region : undefined,
    customerName: searchCriteria.customerName ? searchCriteria.customerName : undefined,
    quoteId: searchCriteria.quoteId ? searchCriteria.quoteId : undefined,
    rtaNumber: searchCriteria.rtaNumber ? searchCriteria.rtaNumber : undefined,
    fieldSalesOrder: searchCriteria.fieldSalesOrder ? searchCriteria.fieldSalesOrder : undefined,
    manufacturingSalesOrder: searchCriteria.manufacturingSalesOrder
      ? searchCriteria.manufacturingSalesOrder
      : undefined,
    createDateOption: searchCriteria.createDateOption ? searchCriteria.createDateOption : undefined,
    statusOption: searchCriteria.statusOption ? searchCriteria.statusOption : undefined,
    amount: searchCriteria.amount ? searchCriteria.amount : undefined,
    skip: searchCriteria.skip ? searchCriteria.skip : undefined,
  };

  return apiInstance.post('Projects/Search', searchParams);
};
