import { useEffect } from 'react';
import {
  setLoading,
  setProjectsPagination,
  clearProjects,
  appendProjects,
  setSummaryHeader,
  setProjectsConfigSkip,
  setProjectsConfigReverseOrder,
  setProjectsConfigSearchValue,
  setProjectsConfigSortOption,
  setProjectsShownAmount,
  setSidebar,
} from '../features/state/slices/homeSlice';
import { setBackPressed } from '../features/state/slices/configSlice';
import { COUNTRIES, HOME_PROJECTS_LOADING_STEP } from '../constants/home';
import { useNavigate } from 'react-router-dom';
import { getProjects } from '../api/endpoints/projects';
import { useApi } from '../api/ApiContext';
import { useAppDispatch, useAppSelector } from '../features/state/hooks';
import ProjectCardsSection from '../components/common/ProjectCardsSection';
import CollapsableFilterGroupList from '../components/common/CollapsableFilterGroupList';
import { ProjectItem } from '../types/ProjectTypes';
import { ProjectStatus, ProjectType } from '../constants/projectItem';
import { CollapsableGroupListData } from '../types/CollapsableFilterTypes';
import { searchUrl } from '../globalConfig';
import DOMPurify from 'dompurify';

export const routeName = 'home';
const msToTriggerSearch = 1000;

const Home = () => {
  const { apiInstance } = useApi();
  let clearTimeoutId: NodeJS.Timeout;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { summaryHeaderInfo } = useAppSelector((state) => state.home.value);
  const {
    skipProjectAmt,
    sortOption,
    reverseOrder,
    searchValue,
    selectedCountry,
    selectedYear,
    projectsShownAmount,
  } = useAppSelector((state) => state.home.value.projectsConfig);
  const { projects, sidebar, hideableFilter } = useAppSelector((state) => state.home.value);
  const backPressed = useAppSelector((state) => state.config.value.backPressed);

  useEffect(() => {
    if (backPressed) {
      dispatch(setBackPressed(false));
      return;
    }

    dispatch(setLoading(true));
    getProjects(
      apiInstance,
      HOME_PROJECTS_LOADING_STEP,
      skipProjectAmt,
      sortOption.config.sortType,
      reverseOrder,
      searchValue,
      hideableFilter.selectedGroup,
      selectedCountry,
      selectedYear,
    )
      .then((response) => {
        dispatch(
          setProjectsPagination({
            resultsStart: response.responseAsJson.resultsStart,
            resultsEnd: response.responseAsJson.resultsEnd,
            totalResults: response.responseAsJson.totalResults,
          }),
        );
        const newProjects: ProjectItem[] = response.responseAsJson.projects.map((project: any) => {
          return {
            projectId: project.projectId,
            projectProgress: {
              initiation: project.progressStages.quoteProgress,
              technology: project.progressStages.technologyProgress,
              manufacturing: project.progressStages.manufacturingProgress,
              operations: project.progressStages.operationsProgress,
            },
            quoteCount: project.quoteCount,
            projectName: project.projectname.substring(0, 25), // Temporary substring to avoid overflow in the card. We're switching out of this design, good for now
            projectStatus: ProjectStatus.operations,
            wellsAmount: project.wellInfo?.count || 0,
            projectType: ProjectType.onShore,
            estimatedOnSiteDate: project.estimatedOnSiteDate,
            quoteId: project.quoteId,
            fieldSalesOrder: project.fieldSalesOrder,
          };
        });

        dispatch(appendProjects(newProjects));

        const countriesByRegionKeys = Object.keys(response.responseAsJson.countriesByRegion || {});
        const projectCountByYearKeys = Object.keys(
          response.responseAsJson.projectsCountByYear || {},
        );
        dispatch(
          setSidebar({
            countriesByRegion: countriesByRegionKeys.map((key) => ({
              name: key,
              countries: response.responseAsJson.countriesByRegion[key].map(
                (country: string) => country,
              ),
            })),
            projectsCountByYear: projectCountByYearKeys.map((key) => [
              key,
              response.responseAsJson.projectsCountByYear[key],
            ]),
          }),
        );

        const newTotalProjects = response.responseAsJson.totalResults;
        dispatch(
          setSummaryHeader({
            totalProjects: newTotalProjects,
            activeTotalJobs: `*12`,
          }),
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      })
      .catch((error) => {});
  }, [
    dispatch,
    navigate,
    skipProjectAmt,
    reverseOrder,
    sortOption,
    searchValue,
    selectedCountry,
    selectedYear,
    backPressed,
    summaryHeaderInfo.totalProjects,
    apiInstance,
    projectsShownAmount,
    hideableFilter.selectedGroup,
  ]);

  const onSortChange = (sortOption: { config: { reverseOrder: any } }) => {
    dispatch(clearProjects());
    dispatch(setProjectsShownAmount(HOME_PROJECTS_LOADING_STEP));
    dispatch(setProjectsConfigSortOption(sortOption));
    dispatch(setProjectsConfigReverseOrder(sortOption.config.reverseOrder));
    dispatch(setProjectsConfigSkip(0));
  };

  const onSearchChange = (searchValue: any) => {
    // Debounce search
    if (clearTimeoutId) {
      clearTimeout(clearTimeoutId);
    }
    clearTimeoutId = setTimeout(() => {
      dispatch(setProjectsShownAmount(HOME_PROJECTS_LOADING_STEP));
      dispatch(clearProjects());
      dispatch(setProjectsConfigSearchValue(searchValue));
      dispatch(setProjectsConfigSkip(0));
    }, msToTriggerSearch);
  };

  const sidebarRegions = sidebar.countriesByRegion.map((region) => ({
    [region.name]: region.countries.map((country) => ({
      id: country,
      name: COUNTRIES[country as keyof typeof COUNTRIES] || country,
    })),
  }));

  const sidebarPastProjects = sidebar.projectsCountByYear.map(([year, count]) => ({
    id: year,
    name: year,
  }));

  const sidebarData: CollapsableGroupListData = {
    Region: sidebarRegions.reduce((acc, region) => ({ ...acc, ...region }), {}),
    'Past Projects': sidebarPastProjects,
  };

  return (
    <div className="main-container home-container">
      <div className="home-content-container">
        <CollapsableFilterGroupList
          onSearchChange={onSearchChange}
          onSortChange={onSortChange}
          groupListData={sidebarData}
        />
        <div className="projects-container">
          <div className="projects-banner">
            <span className="projects-banner-label">
              Complete formation isolation and fluid-loss control
            </span>
            <a
              rel="noreferrer"
              className="projects-banner-button"
              href={DOMPurify.sanitize(searchUrl)}
              target="_blank">
              Shop Now
            </a>
          </div>
          <ProjectCardsSection
            onSearchChange={onSearchChange}
            onSortChange={onSortChange}
            projectCards={projects}
            showActive={true}
          />

          {/*<div className="home-pagination-container">
            <div className="pagination">
              <button
                className="pagination-btn pagination-btn-left"
                disabled={disablePrevious}
                onClick={() => {
                  onPageChange(HOME_PAGINATION.PREVIOUS);
                }}>
                <span className="material-icons">Previous</span>
              </button> 
              <span className="pagination-text">
                {`Showing ${resultsStart}-${resultsEnd} of ${totalResults} projects`}
              </span>
               <button
                className="pagination-btn pagination-btn-right"
                disabled={disableNext}
                onClick={() => {
                  onPageChange(HOME_PAGINATION.NEXT);
                }}>
                <span className="material-icons">Next</span>
              </button> 
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Home;
