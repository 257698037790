import ButtonCaret from '../../assets/icons/button_caret_black.svg';
import { ProjectStage } from '../../types/ProjectTypes';

interface BannerProgressItemHeaderProps {
  step: ProjectStage;
  percentageCompleted: number;
  onClickExpand: () => void;
  expanded: boolean;
}

const BannerProgressItemHeader = ({
  step,
  percentageCompleted,
  onClickExpand,
  expanded,
}: BannerProgressItemHeaderProps) => {
  return (
    <div className="banner-progress-item-header-container">
      <div className="title-collapse-container">
        <img
          className={`collapse-btn ${expanded ? 'expanded' : ''}`}
          src={ButtonCaret}
          alt="Button Caret"
          onClick={onClickExpand}
        />
        <div className="title-container">
          <div className="title">{step.title}</div>
          <div className="subtitle">{step.subtitle}</div>
        </div>
      </div>
      <div className="progress-container">
        <div className="progress-bar">
          <div className={`progress width-${percentageCompleted}`} />
        </div>
        <span className="progress-percentage">{percentageCompleted}%</span>
      </div>
    </div>
  );
};

export default BannerProgressItemHeader;
