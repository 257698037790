export type Json = { [key: string]: any };

export default class ModelBase {
  // Every model should create their own fromJsonToReduxJson method, returning a JSON object
  // not a class Instance to make it work with redux toolkit
  static fromJsonToReduxJson(json: Json): Json {
    throw new Error('fromJsonToReduxJson method not implemented');
  }

  // Build a JSON object from the class instance
  toJson(): Json {
    const json: Json = {};
    Object.keys(this).forEach((key) => {
      if ((this as any)[key] !== undefined) {
        json[key] = (this as any)[key];
      }
    });
    return json;
  }

  // Will parse an array of json objects from the API to an array of redux json objects
  // matching the model it corresponds to, using its fromJsonToReduxJson method
  static fromJsonArrayToObjectReduxJsonArray(jsonArray: Json[]): Json[] {
    return jsonArray.map((json) => this.fromJsonToReduxJson(json));
  }
}
