/* eslint-disable no-console */
import { useEffect } from 'react';
import ProjectDetails from '../components/pages/ProjectDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getProjectStages } from '../api/endpoints/projects';
import { setStages, reset, setProject } from '../features/state/slices/projectSlice';
import { useApi } from '../api/ApiContext';
import { useAppDispatch } from '../features/state/hooks';
import { useOktaAuth } from '@okta/okta-react';
import { routeName as homeRouteName } from './Home';
import { routeName as searchRouteName } from './Search';

export const routeName = 'project';

const Project = () => {
  const { apiInstance } = useApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useOktaAuth();
  const { id } = useParams();

  const goToSummary = (soNumber: string) => {
    navigate(`/${routeName}/${id}/job/${soNumber}`);
  };

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (!apiInstance.oktaInitialized) return; // When the user navigates directly to a project url

    getProjectStages(apiInstance, id || '')
      .then((res) => {
        const projectStagesData = res.responseAsJson;
        dispatch(
          setProject({
            name: projectStagesData.projectName,
            customerName: projectStagesData.oniQuoteStages[0]?.customerName,
            projectId: projectStagesData.projectId,
            region: projectStagesData.oniQuoteStages[0]?.region,
            quoteCount: projectStagesData.oniQuoteStages.length,
            jobCount: projectStagesData.oniOperationsStages.length,
            wellInfo: {
              count: projectStagesData.wellInfo!.count,
              offShore: projectStagesData?.wellInfo!.offShore,
            },
            stage: projectStagesData.stage,
          }),
        );

        dispatch(
          setStages({
            businessDevelopment: projectStagesData.oniQuoteStages,
            technology: projectStagesData.oniTechnologyStages,
            manufacturingData: projectStagesData.oniManufacturingStages,
            operation: projectStagesData.oniOperationsStages,
            milestones: projectStagesData.milestones,
          }),
        );
      })
      .catch((e) => {
        console.log('SalesOrder: Error on getProjectStages', e);
        if (authState?.isAuthenticated && apiInstance.oktaInitialized) {
          if (!e.ok) {
            console.log('SalesOrder: Redirecting to home, invalid response');
            navigate('/'); // Invalid project, redirect to home
          }
        }
      });
  }, [id, dispatch, navigate, authState?.isAuthenticated, apiInstance]);

  const isSearchOrigin = location.state && location.state.origin === searchRouteName;
  return (
    <div className="project-container">
      <aside className="project">
        <span
          className="back"
          onClick={() => navigate(`/${isSearchOrigin ? searchRouteName : homeRouteName}`)}>
          Back
          {isSearchOrigin ? ' to Search' : ' to Home'}
        </span>
      </aside>
      <section className="project">
        <ProjectDetails goToSummary={goToSummary} />
      </section>
    </div>
  );
};

export default Project;
