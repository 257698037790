import { CollapsableGroupData } from '../../types/CollapsableFilterTypes';
import CollapsableFilter from './CollapsableFilter';

const CollapsableFilterGroup = ({
  filterGroupData,
  listHeader,
}: {
  filterGroupData: CollapsableGroupData;
  listHeader: string;
}) => {
  return (
    <div className="aside-filter-section-group expandable-section-group">
      <span className="aside-filter-section-group-header">{listHeader}</span>
      <div className="aside-filter-section-group-content">
        {Object.entries(filterGroupData).map(([sectionName, arrOfEntities]) => (
          <CollapsableFilter
            key={sectionName}
            arrOfEntities={arrOfEntities}
            sectionName={sectionName}
          />
        ))}
      </div>
    </div>
  );
};

export default CollapsableFilterGroup;
