import { configureStore } from '@reduxjs/toolkit';
import dialogsReducer from './slices/dialogsSlice';
import notificationsReducer from './slices/notificationsSlice';
import currentUserReducer from './slices/currentUserSlice';
import projectReducer from './slices/projectSlice';
import homeReducer from './slices/homeSlice';
import configReducer from './slices/configSlice';
import searchReducer from './slices/searchSlice';

export const makeStore = () => {
  return configureStore({
    reducer: {
      dialog: dialogsReducer,
      notifications: notificationsReducer,
      currentUser: currentUserReducer,
      project: projectReducer,
      home: homeReducer,
      config: configReducer,
      search: searchReducer,
    },
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
