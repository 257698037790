import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MILESTONE_STATUS } from '../../../constants/salesOrder';
import { IProject, ProjectDetails } from '../../../models/project';

export interface ProjectState {
  value: IProject;
}

const initialState: ProjectState = {
  value: {
    project: {
      name: '',
      projectId: '',
      customerName: '',
      region: '',
      quoteCount: '',
      jobCount: '',
      stage: '',
      wellInfo: {
        count: 0,
        offShore: false,
      },
    },
    projectSummary: {
      customerName: '',
      region: '',
      soNumber: '',
      dosNumber: '',
      jobName: '',
      wellName: '',
      location: '',
      riskFactor: '',
      tierLevel: '',
      jobType: '',
      ploNumber: '',
      jobStatusCode: '',
      productOrService: '',
      projectDistributionList: '',
      controlPoint: '',
      jobContacts: '',
    },
    stages: {
      businessDevelopment: [
        // {
        //   percentageCompleted: 100,
        //   quoteId: 'ID-110054257-2024-00-02',
        //   submittedToCustomerDate: '2024-04-17T00:00:00',
        //   quoteValidityDate: '2024-06-25T00:00:00',
        //   loiReceivedDate: '2024-04-17T00:00:00',
        //   region: 'Asia Pacific Summary',
        // },
      ],
      technology: [
        // {
        //   percentageCompleted: 67,
        //   rtaNumber: 'R00001581153',
        //   submittedDate: '2021-06-16T02:31:50',
        //   targetCompletedDate: null,
        //   actualCompletedDate: '2021-08-20T01:44:11',
        // },
      ],
      manufacturingData: [
        // {
        //   percentageCompleted: 100,
        //   soNumber: '0909288790',
        //   wellName: '',
        //   targetExWorksCompleteDate: '2024-06-14T00:00:00',
        //   projectedCompleteDate: '2024-07-12T00:00:00',
        // },
      ],
      operation: [
        // {
        //   soNumber: '*905493241',
        //   dosNumber: '*110983',
        //   jobId: '*6345',
        //   jobTiming: '*Job Timing',
        //   actualOnLocationDate: null,
        //   percentageCompleted: 0,
        // },
      ],
      dispatchedToField: [
        {
          title: 'Dispatched to Field',
          completionDate: MILESTONE_STATUS.NotStarted,
        },
        {
          title: 'Fieldwork Complete',
          completionDate: MILESTONE_STATUS.NotStarted,
        },
        { title: 'Job Complete', completionDate: MILESTONE_STATUS.NotStarted },
      ],
    },
  },
};

export const projectSlice = createSlice({
  name: 'salesOrder',
  initialState,
  reducers: {
    reset: (state) => {
      state.value = initialState.value;
    },
    setProject: (state, action: PayloadAction<ProjectDetails>) => {
      state.value.project = { ...state.value.project, ...action.payload };
    },
    setProjectSummary: (state, action) => {
      state.value.projectSummary = {
        ...state.value.projectSummary,
        ...action.payload,
      };
    },
    setStages: (state, action) => {
      const milestones = action.payload['milestones'];
      state.value.stages = {
        ...action.payload,
        dispatchedToField: [
          {
            title: 'Dispatched to Field',
            completionDate:
              MILESTONE_STATUS[milestones['dispatchedToField'] as keyof typeof MILESTONE_STATUS],
          },
          {
            title: 'Fieldwork Complete',
            completionDate:
              MILESTONE_STATUS[milestones['fieldworkComplete'] as keyof typeof MILESTONE_STATUS],
          },
          {
            title: 'Job Complete',
            completionDate:
              MILESTONE_STATUS[milestones['jobComplete'] as keyof typeof MILESTONE_STATUS],
          },
        ],
      };
    },
  },
});

export const { reset, setProject, setStages, setProjectSummary } = projectSlice.actions;

export default projectSlice.reducer;
