import { sortTypes } from '../../api/endpoints/projects';
import { useAppSelector } from '../../features/state/hooks';
import { ChangeEvent, useState } from 'react';

export const sortOptions = [
  {
    key: 'last-mod-newest-to-oldest',
    title: 'Last modified newest to oldest',
    config: {
      sortType: sortTypes.LAST_MOD_DATE,
      reverseOrder: true,
    },
  },
  {
    key: 'last-mod-oldest-to-newest',
    title: 'Last modified oldest to newest',
    config: {
      sortType: sortTypes.LAST_MOD_DATE,
      reverseOrder: false,
    },
  },
  {
    key: 'customer-name-a-to-z',
    title: 'Customer name A to Z',
    config: {
      sortType: sortTypes.CUSTOMER_NAME,
      reverseOrder: false,
    },
  },
  {
    key: 'customer-name-z-to-a',
    title: 'Customer name Z to A',
    config: {
      sortType: sortTypes.CUSTOMER_NAME,
      reverseOrder: true,
    },
  },
];

interface SearchAndSortPanelProps {
  onSortChange: (sortOption: { config: { reverseOrder: any } }) => void;
  onSearchChange: (searchValue: string) => void;
}

const SearchAndSortPanel = ({ onSearchChange, onSortChange }: SearchAndSortPanelProps) => {
  const filtersOpen = useAppSelector((state) => state.home.value.hideableFilter.filtersOpen);
  const { searchValue: stateSearchValue, sortOption: stateSortOption } = useAppSelector(
    (state) => state.home.value.projectsConfig,
  );
  const [searchValue, setSearchValue] = useState(stateSearchValue);
  const [selectedSortBy, setSelectedSortBy] = useState(stateSortOption.key);

  const _onSortChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedSortOption = sortOptions.find((option) => option.key === e.target.value);
    setSelectedSortBy(selectedSortOption?.key || '');
    onSortChange(selectedSortOption!);
  };

  const _onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
    onSearchChange(newSearchValue);
  };

  return (
    <div className={`filters${filtersOpen ? ' opened' : ''}`}>
      <div className="filter">
        <span>Filter</span>
        <input
          className="filter-container"
          type="text"
          placeholder="Project name, ID, customer, region"
          value={searchValue}
          onChange={_onSearchChange}
        />
      </div>
      <div className="filter">
        <span>Sort By</span>
        <select
          className="filter-container"
          name="type"
          id="type"
          value={selectedSortBy}
          onChange={_onSortChange}>
          {sortOptions.map((sortOption) => (
            <option key={sortOption.key} value={sortOption.key}>
              {sortOption.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SearchAndSortPanel;
