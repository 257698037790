export const getInitials = (name: string) => {
  const nameArray = name.split(' ');
  const initials = nameArray.map((name) => name[0]);
  return initials.join('');
};

const alphabetMap: { [key: string]: number } = {};
for (let i = 0; i < 26; i++) {
  alphabetMap[String.fromCharCode(65 + i)] = i + 1;
}

export const getAlphabetPosition = (letter: string): number | undefined => {
  return alphabetMap[letter];
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
