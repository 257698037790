/* eslint-disable no-console */
import ReactModal from 'react-modal';

import { oktaSignInInstance, useOktaAuthContext } from '../../features/auth/oktaAuth';

const OktaAuthModal = () => {
  ReactModal.setAppElement('#root');
  const { isOktaModalOpen, closeOktaModal, redirectPath } = useOktaAuthContext();

  return (
    <ReactModal
      isOpen={isOktaModalOpen}
      onRequestClose={closeOktaModal}
      contentLabel="Auth Modal"
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          background: 'rgba(67, 73, 78, 0.5)',
          zIndex: '1000',
        },
        content: {
          display: 'flex',
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          outline: 'none',
          border: 'none',
          background: 'none',
          overflow: 'none',
          width: '0px',
          height: '0px',
        },
      }}
      onAfterOpen={() => {
        sessionStorage.setItem(
          'preAuthPath',

          redirectPath ?? '/',
        );

        oktaSignInInstance
          .showSignInAndRedirect({
            el: '#okta-auth-div',
            state: JSON.stringify(redirectPath),
          })
          .then(() => {
            closeOktaModal();
          })
          .catch((error) => {
            console.error('LOGIN ERROR', error);
          });
      }}>
      <div id="okta-auth-div" />
    </ReactModal>
  );
};

export default OktaAuthModal;
