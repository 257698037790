import { ProjectSubHeaderItem } from '../../../types/ProjectTypes';

// CSS classes here will be added to the subheader item rendered
export const SubHeaderItemType = {
  INFO: { key: 'info', title: '' },
  JOB_STAGE: { key: 'job-stage', title: 'Project stage' },
};

interface ProjectDetailsHeaderProps {
  subHeaderItems: ProjectSubHeaderItem[];
}

const ProjectDetailsHeader = ({ subHeaderItems }: ProjectDetailsHeaderProps) => {
  const jobStageTags = subHeaderItems.find((item) => item.type === SubHeaderItemType.JOB_STAGE.key);

  return (
    <div className="project-details-header">
      <div className="header-info">
        <div className="tags">
          <section id="sub-header-tags">
            {subHeaderItems
              .filter((item) => item.type !== SubHeaderItemType.JOB_STAGE.key)
              .map((item, idx) => (
                <div key={idx} className="sub-item">
                  <div className="header-item">
                    <h5>{item.title}</h5>
                  </div>
                  <div className="value-container">
                    <span className={`${item.type} value`}>{item.value}</span>
                  </div>
                </div>
              ))}
            <div className="sub-item">
              <div className="header-item">
                <h5>{SubHeaderItemType.JOB_STAGE.title}</h5>
              </div>
              <div className="value-container">{jobStageTags?.value || ''}</div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsHeader;
