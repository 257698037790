interface ContainerWithAsidePanelProps {
  content: React.ReactElement;
  asideContent: React.ReactElement;
  hideAsideOnMobile?: boolean;
}

export const ContainerWithAsidePanel = ({
  content,
  asideContent,
  hideAsideOnMobile,
}: ContainerWithAsidePanelProps) => {
  const showAside = Array.isArray(asideContent.props.children)
    ? (asideContent.props.children as Array<React.ReactElement | Boolean>).some((c) => !!c)
    : !!asideContent.props.children;
  return (
    <section className="container-with-aside-panel">
      <div className="content">{content}</div>
      {showAside && (
        <aside className={hideAsideOnMobile ? 'hide-on-mobile' : ''}>{asideContent}</aside>
      )}
    </section>
  );
};
