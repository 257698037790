/* eslint-disable no-console */
import { createContext, useContext, useEffect, useState } from 'react';
import { ApiBase } from './apiBase';
import { apiBaseUrl } from '../globalConfig';
import { useOktaAuth } from '@okta/okta-react';
import { useOktaAuthContext } from '../features/auth/oktaAuth';

// Define the shape of the context value
interface ApiContextValue {
  apiInstance: ApiBase;
  setOktaInitialized: (initialized: boolean) => void;
}

// Create the context with a default value of undefined
const ApiContext = createContext<ApiContextValue | undefined>(undefined);

type ApiProviderProps = {
  children: React.ReactNode;
};

export const ApiProvider = ({ children }: ApiProviderProps) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { openOktaModal } = useOktaAuthContext();
  const [apiInstance, setApiInstance] = useState(
    new ApiBase(apiBaseUrl, oktaAuth, false, authState, openOktaModal),
  );
  const [oktaInitialized, _setOktaInitialized] = useState(false);

  useEffect(() => {
    console.log('ApiProvider: Setting apiInstance', oktaInitialized);
    const newApiInstance = new ApiBase(
      apiBaseUrl,
      oktaAuth,
      oktaInitialized,
      authState,
      openOktaModal,
    );
    setApiInstance(newApiInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, oktaInitialized, authState]);

  const setOktaInitialized = (initialized: boolean) => {
    console.log('ApiProvider: Setting oktaInitialized', initialized);
    _setOktaInitialized(initialized);
  };

  return (
    <ApiContext.Provider
      value={{
        apiInstance, // Access oktaInitialized from apiInstance
        setOktaInitialized,
      }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};
