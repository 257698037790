import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useApi } from '../../api/ApiContext';
import { sanitizeDeepLink } from '../../utils/routing';

type AuthPageRouterProps = {
  AppRoutes: JSX.Element;
};

export const AuthPageRouter = ({ AppRoutes }: AuthPageRouterProps) => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { apiInstance } = useApi();
  const deepLinkRoute = `/${window.location.pathname.split('/')?.slice(1).join('/')}`;

  useEffect(() => {
    // Redirect to landing page if not authenticated
    const notAuthenticated = authState && !authState?.isAuthenticated;
    if (notAuthenticated) {
      const notLoginNorHome =
        deepLinkRoute !== '/' && deepLinkRoute !== '/login' && deepLinkRoute !== '/home';
      if (notLoginNorHome) {
        localStorage.setItem('deepLinkRoute', deepLinkRoute); // Save deepLink route
      }
      navigate('/');
    } else {
      if (!apiInstance.oktaInitialized) return; // Wait for API initialization and navigate to deepLink route
      const deepLinkRoute = localStorage.getItem('deepLinkRoute');
      const constructedUrl = sanitizeDeepLink(deepLinkRoute || '');

      localStorage.removeItem('deepLinkRoute');
      if (!constructedUrl) return; // No deeplink route to navigate to
      navigate(constructedUrl);
    }
  }, [navigate, deepLinkRoute, authState, apiInstance]);

  return AppRoutes;
};
