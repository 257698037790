/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// OKTA
import { Security } from '@okta/okta-react';
import { OktaAuthProvider } from './features/auth/oktaAuth';
import { oktaAuth } from './features/auth/oktaAuth';

import App from './App';
import StoreProvider from './features/state/StoreProvider';
import { ApiProvider } from './api/ApiContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <StoreProvider>
      <BrowserRouter>
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={(oktaAuth) => oktaAuth.setOriginalUri(window.location.origin)}>
          <OktaAuthProvider>
            <ApiProvider>
              <App />
            </ApiProvider>
          </OktaAuthProvider>
        </Security>
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
);
