import { createSlice } from '@reduxjs/toolkit';

export interface DialogState {
  value: {
    open: boolean,
    title: string,
    message: string,
  };
}

const initialState: DialogState = {
  value: {
    open: false,
    title: 'Looks like something went wrong.',
    message:
      'Please check your connection and try again. If the error persists please contact support.',
  },
};

export const dialogsSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.value = action.payload;
    },
    setDialogMessage: (state, action) => {
      state.value.message = action.payload;
    },
    openDialog: (state) => {
      state.value.open = true;
    },
    closeDialog: (state) => {
      state.value.open = false;
    },
  },
});

export const { setDialog, setDialogMessage, openDialog, closeDialog } = dialogsSlice.actions;

export default dialogsSlice.reducer;
