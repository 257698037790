import { createSlice } from '@reduxjs/toolkit';

export interface ConfigState {
  value: {
    backPressed: boolean;
  };
}

const initialState: ConfigState = {
  value: {
    backPressed: false,
  },
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setBackPressed: (state, action) => {
      state.value.backPressed = action.payload;
    },
  },
});

export const { setBackPressed } = configSlice.actions;

export default configSlice.reducer;
