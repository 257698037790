import { ApiBase, ApiResponse } from '../apiBase';

/**
 * Retrieve users for the given ids
 */
export const getUsers = (apiInstance: ApiBase, userIds: string[]): Promise<ApiResponse> => {
  const params = new URLSearchParams(userIds.map((id) => ['userIds', id]));
  return apiInstance.get(`Users/?${params}`);
};

/**
 * Retrieve the logged in user record
 * and creates one if it doesn't yet exist.
 */
export const getCurrentUser = (apiInstance: ApiBase): Promise<ApiResponse> => {
  return apiInstance.post('Users/GetOrCreate');
};
