import { ApiBase, ApiResponse } from '../apiBase';

/**
 * Retrieve sales order stages.
 */
export const getStagesDataForSo = (
  apiInstance: ApiBase,
  soNumber: string,
): Promise<ApiResponse> => {
  return apiInstance.get(`Job/SalesOrder/${soNumber}/Stages`);
};

/**
 * Retrieve job data for the given sales order
 */
export const getProjectSummaryForSo = (
  apiInstance: ApiBase,
  soNumber: string,
): Promise<ApiResponse> => {
  return apiInstance.get(`Job/SalesOrder/${soNumber}`);
};
