/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import ChartHeader from './ChartHeader';
import { SubHeaderItemType } from './ProjectDetailsHeader';
import ContainerWithNotifications from '../../common/ContainerWithNotifications';
import ProjectStages from './ProjectStages';
import { COMPLETION_DATE_STATUS } from '../../../constants/salesOrder';
import { removeLeadingZeroes } from '../../../utils/numbers';
import { useAppSelector } from '../../../features/state/hooks';
import {
  ProjectStage,
  ProjectStageChartItem,
  ProjectSubHeaderItem,
} from '../../../types/ProjectTypes';
import { IProject } from '../../../models/project';
import { ContainerWithAsidePanel } from '../../common/ContainerWithAsidePanel';
import { useNavigate } from 'react-router-dom';
import ProjectDetailsHeader from './ProjectDetailsHeader';
// import { ProjectDetailsMap } from './ProjectDetailsMap';
import { useWindowSize } from '../../../utils/hooks/useWindowSize';
import CaretIcon from '../../../assets/icons/button_caret_red.svg';
import { PushPin } from '../../../components/pages/ProjectDetails/ProjectDetailsMap';

const projectStagesIds = {
  businessDevelopment: 'businessDevelopment',
  technology: 'technology',
  manufacturing: 'manufacturing',
  operations: 'operations',
};

// re #177775 – applying special naming to some materials
const namedMaterials: { [key: string]: string } = {
  '728614': 'Pending Technology; Material No. TBD',
  '728615': '3rd Party Item; Material No. TBD',
  '728616': 'Pending Extension; Material No. TBD',
};

interface ProjectDetailsProps {
  goToSummary: (soNumber: string) => void;
}

const ProjectDetails = ({ goToSummary }: ProjectDetailsProps) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const project = useAppSelector((state) => state.project.value);
  const projectNotifications = useAppSelector((state) => state.notifications.value.project);

  const [projectStages, setProjectStages] = useState<ProjectStage[]>(generateStagesItems(project));
  const [chartItems, setChartItems] = useState<ProjectStageChartItem[]>(
    generateChartItems(projectStages),
  );
  const [subHeaderItems, setSubHeaderItems] = useState(generateSubHeaderItems(project));

  useEffect(() => {
    setProjectStages(generateStagesItems(project));
    setSubHeaderItems(generateSubHeaderItems(project));
  }, [project]);

  useEffect(() => {
    setChartItems(generateChartItems(projectStages));
  }, [projectStages]);

  // const pushPins = projectStages.find((stage) => stage.id == projectStagesIds.operations)?.pushPins;

  return (
    <>
      <ContainerWithAsidePanel
        content={
          <ChartHeader
            projectTitle={project.project.name}
            subHeaderComponent={<ProjectDetailsHeader subHeaderItems={subHeaderItems} />}
            chartItems={chartItems}
          />
        }
        asideContent={
          <>
            {isMobile && projectNotifications.length > 0 && (
              <div
                className="project-notification-banner"
                onClick={() => navigate('/notifications')}>
                <div>
                  <span id="count">{projectNotifications.length}</span>
                  Notifications
                </div>
                <img alt="caret" src={CaretIcon} />
              </div>
            )}
            {
              // re #177312 - hiding for now
              /*!!pushPins?.length && (
              <ProjectDetailsMap
                pushPins={[
                  {
                    center: { latitude: 27.98785, longitude: 86.925026 },
                    options: { title: 'Mount Everest' },
                  },
                  {
                    center: { latitude: 27.9881, longitude: 86.925 },
                    options: { title: 'Mount Everest Base Camp' },
                  },
                ]}
              />
            )*/
            }
          </>
        }
        hideAsideOnMobile={false}
      />
      <h4 className="project-stages-title">Stages</h4>
      <ContainerWithNotifications
        content={
          <ProjectStages stages={projectStages} chartItems={chartItems} goToSummary={goToSummary} />
        }
      />
    </>
  );
};

// #region Generator functions
const parseDate = (date: string) => {
  if (date === COMPLETION_DATE_STATUS.TBD) return COMPLETION_DATE_STATUS.TBD;
  return date ? new Date(date).toLocaleDateString('en-US') : COMPLETION_DATE_STATUS.TBD;
};

// re #177775 – applying special naming to some materials
const replaceNamedMaterials = (materialNumber: string): string => {
  return namedMaterials[materialNumber] || materialNumber;
};

const calculateCompletedCount = (projectStages: ProjectStage[], jobStageId: string) => {
  const stageItems = projectStages.find((item) => item.id === jobStageId);
  return stageItems!.itemValues!.reduce((acc, item) => {
    const rowProgress = stageItems!.rowProgress!(item);
    const progress =
      rowProgress === COMPLETION_DATE_STATUS.COMPLETED
        ? 1
        : rowProgress === COMPLETION_DATE_STATUS.IN_PROGRESS
        ? 0.5
        : 0;
    return acc + progress;
  }, 0);
};

const generatePushPin = (pinTitle: string, siteAddress?: string): PushPin | null => {
  if (siteAddress) {
    const regex =
      /Surface Latitude:\s*([-+]?\d*\.\d+|[-+]?\d+)\s*'\s*Surface Longitude:\s*([-+]?\d*\.\d+|[-+]?\d+)/;

    const match = siteAddress.match(regex);
    if (match) {
      const latitude = match[1];
      const longitude = match[2];
      return {
        center: {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        },
        options: {
          title: pinTitle,
        },
      };
    }
  }
  return null;
};

/*
  Generates the sub header items for the project

  Sub header items are generated based on the project data. Each sub header item contains the following properties:
  - type: The type of the sub header item. This is used to determine the design of the item, in V2 we only have 1 design, but in V1 we had 2 designs
  - title: The title of the sub header item
  - value: The value of the sub header item
*/
const generateSubHeaderItems = (project: IProject): ProjectSubHeaderItem[] => [
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Project ID',
    value: project.project.projectId,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Customer name',
    value: project.project.customerName,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Region',
    value: project.project.region,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Wells',
    value: project.project.wellInfo.count.toString(),
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Location',
    value: project.project.wellInfo.offShore ? 'OffShore' : 'OnShore',
  },
  {
    type: SubHeaderItemType.JOB_STAGE.key,
    title: SubHeaderItemType.JOB_STAGE.title,
    value: project.project.stage,
  },
];

/*
  Generates the project stages data

  Stage items are generated based on the project data. Each stage item contains the following properties:
  - id: The stage id
  - isMilestone: Indicates if the stage is a milestone, the design is different for milestones
  - title: The stage title
  - subtitle: The stage subtitle
  - itemHeaders: The headers for the stage items
    - title: The header title
    - boldValue: Indicates if the value in itemValues rows rendered should be bold
    - size: The total amount of space the column will take, this also affects the size of each row's column size. 
            The sum of these need to add up to 95, as the first column (status symbol) takes 5
  - itemValues: The values for the stage items. Each row is an array of values, where the indices match the columns in order set in itemHeaders
  - rowProgress: A method that receives an item array (itemValue row), where the indices match the columns in order (from itemHeaders), and returns the progress status of the row (COMPLETION_DATE_STATUS TBD, IN_PROGRESS, COMPLETED)
  - percentageCompleted: The percentage of the stage item that is completed
*/
const generateStagesItems = (project: IProject): ProjectStage[] => [
  {
    id: projectStagesIds.businessDevelopment,
    isMilestone: false,
    title: 'Initiation',
    subtitle: 'Details about your Quotes',
    itemHeaders: [
      { title: 'Quote ID', size: 25, boldValue: true },
      { title: 'Submitted to Customer Date', size: 25 },
      { title: 'Quote Validity Date', size: 25 },
      { title: 'LOI Received Date', size: 20 },
    ],
    itemValues: project.stages.businessDevelopment.map((item) => [
      item.quoteId,
      parseDate(item.submittedToCustomerDate),
      parseDate(item.quoteValidityDate),
      parseDate(item.loiReceivedDate),
    ]),
    rowProgress: (item) => {
      const submittedDate = item[1];
      const targetCompletedDate = item[2];
      const actualCompletedDate = item[3];

      if (actualCompletedDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const actualCompletedDateInTheFuture = new Date(actualCompletedDate) > new Date();
        return actualCompletedDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    percentageCompleted: project.stages.businessDevelopment.length
      ? Math.ceil(
          project.stages.businessDevelopment.reduce(
            (currentVal, previousVal) => currentVal + previousVal.percentageCompleted,
            0,
          ) / project.stages.businessDevelopment.length,
        )
      : 0,
  },
  {
    id: projectStagesIds.technology,
    isMilestone: false,
    title: 'Technology',
    subtitle: 'Details about your RTAs',
    itemHeaders: [
      { title: 'RTA Number', size: 25, boldValue: true },
      { title: 'Submitted', size: 25 },
      { title: 'Target Completed Date', size: 25 },
      { title: 'Actual Completed Date', size: 20 },
    ],
    itemValues: project.stages.technology.map((item) => [
      item.rtaNumber,
      parseDate(item.submittedDate),
      parseDate(item.targetCompletedDate),
      parseDate(item.actualCompletedDate),
    ]),
    rowProgress: (item) => {
      const submittedDate = item[1];
      const targetCompletedDate = item[2];
      const actualCompletedDate = item[3];

      if (actualCompletedDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const actualCompletedDateInTheFuture = new Date(actualCompletedDate) > new Date();
        return actualCompletedDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    percentageCompleted: project.stages.technology.length
      ? Math.ceil(
          project.stages.technology.reduce(
            (currentVal, previousVal) => currentVal + previousVal.percentageCompleted,
            0,
          ) / project.stages.technology.length,
        )
      : 0,
  },
  {
    id: projectStagesIds.manufacturing,
    isMilestone: false,
    title: 'Manufacturing',
    subtitle: 'Details about your Manufacturing Sales Orders',
    itemHeaders: [
      { title: 'Field SO', size: 20, boldValue: true },
      { title: 'Line Item', size: 20 },
      { title: 'Material Number', size: 20 },
      { title: 'Target Ex Works', size: 20 },
      { title: 'Projected Complete Date', size: 20 },
    ],
    itemValues: project.stages.manufacturingData.map((item) => [
      item.soNumber,
      item.soItemNumber,
      replaceNamedMaterials(removeLeadingZeroes(item.materialNumber)),
      parseDate(item.targetExWorksCompleteDate),
      parseDate(item.projectedCompleteDate),
    ]),
    rowProgress: (item) => {
      const targetExWorksCompleteDate = item[3];
      const projectedCompleteDate = item[4];

      if (projectedCompleteDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const projectedCompleteDateInTheFuture = new Date(projectedCompleteDate) > new Date();
        return projectedCompleteDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    percentageCompleted: project.stages.manufacturingData.length
      ? Math.ceil(
          project.stages.manufacturingData.reduce(
            (currentVal, previousVal) => currentVal + previousVal.percentageCompleted,
            0,
          ) / project.stages.manufacturingData.length,
        )
      : 0,
  },
  {
    id: projectStagesIds.operations,
    isMilestone: false,
    title: 'Operations',
    subtitle: 'Details about your Jobs',
    itemHeaders: [
      { title: 'SO', size: 15, boldValue: true, link: true },
      { title: 'DOS', size: 10 },
      { title: 'Job ID', size: 15 },
      { title: 'Est. Job Timing', size: 15 },
      { title: 'Actual on Location Date', size: 20 },
      { title: 'Job Close Date', size: 20 },
    ],
    itemValues: project.stages.operation.map((item) => [
      item.soNumber,
      item.dosNumber,
      item.jobId,
      item.jobTiming,
      parseDate(item.actualOnLocationDate),
      parseDate(item.jobCloseDate),
    ]),
    rowProgress: (item) => {
      const actualOnLocationDate = item[4];
      const jobCloseDate = item[5];
      const actualDate = new Date();

      if (
        actualOnLocationDate === COMPLETION_DATE_STATUS.TBD &&
        jobCloseDate === COMPLETION_DATE_STATUS.TBD
      ) {
        return COMPLETION_DATE_STATUS.TBD;
      } else if (
        new Date(actualOnLocationDate) < actualDate &&
        new Date(jobCloseDate) < actualDate
      ) {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        return COMPLETION_DATE_STATUS.COMPLETED;
      } else {
        return COMPLETION_DATE_STATUS.IN_PROGRESS;
      }
    },
    pushPins: project.stages.operation
      .map((stage) => generatePushPin(stage.jobId, stage.siteAddress))
      .filter((pin) => !!pin),
    percentageCompleted: project.stages.operation.length
      ? Math.ceil(
          project.stages.operation.reduce(
            (currentVal, previousVal) => currentVal + previousVal.percentageCompleted,
            0,
          ) / project.stages.operation.length,
        )
      : 0,
  },
  {
    isMilestone: true,
    milestones: project.stages.dispatchedToField,
  },
];

/* 
  Generates the chart items for the project stages

  The chart items are generated based on the project stages data. Each chart item contains the following properties:
  - id: The stage id
  - title: The stage title, this'll be the text under the chart
  - completed: The amount of items that are completed in the stage
  - total: The total amount of items in the stage
  - percentageCompleted: The percentage of the stage that is completed
*/
const generateChartItems = (projectStages: ProjectStage[]): ProjectStageChartItem[] => [
  {
    id: projectStagesIds.businessDevelopment,
    title: 'Initation',
    completed: calculateCompletedCount(projectStages, projectStagesIds.businessDevelopment),
    total: projectStages.find((item) => item.id === projectStagesIds.businessDevelopment)!
      .itemValues!.length,
    percentageCompleted:
      projectStages.find((item) => item.id === projectStagesIds.businessDevelopment)!
        .percentageCompleted || 0,
  },
  {
    id: projectStagesIds.technology,
    title: 'Technology',
    completed: calculateCompletedCount(projectStages, projectStagesIds.technology),
    total: projectStages.find((item) => item.id === projectStagesIds.technology)!.itemValues!
      .length,
    percentageCompleted:
      projectStages.find((item) => item.id === projectStagesIds.technology)!.percentageCompleted ||
      0,
  },
  {
    id: projectStagesIds.manufacturing,
    title: 'Manufacturing',
    completed: calculateCompletedCount(projectStages, projectStagesIds.manufacturing),
    total: projectStages.find((item) => item.id === projectStagesIds.manufacturing)!.itemValues!
      .length,
    percentageCompleted:
      projectStages.find((item) => item.id === projectStagesIds.manufacturing)!
        .percentageCompleted || 0,
  },
  {
    id: projectStagesIds.operations,
    title: 'Operations',
    completed: calculateCompletedCount(projectStages, projectStagesIds.operations),
    total: projectStages.find((item) => item.id === projectStagesIds.operations)!.itemValues!
      .length,
    percentageCompleted:
      projectStages.find((item) => item.id === projectStagesIds.operations)!.percentageCompleted ||
      0,
  },
];
// #endregion Generator functions

export default ProjectDetails;
