export const enum ProjectStatus {
  operations = 'Operations',
  manufacturing = 'Manufacturing',
  technology = 'Technology',
  initiation = 'Initiation',
  complete = 'Complete',
}

export const enum ProjectType {
  onShore,
  offShore,
}

export const enum CreateDateOption {
  All = 'All',
  OneDay = 'OneDay',
  WeekToDate = 'WeekToDate',
  MonthToDate = 'MonthToDate',
  SixMonth = 'SixMonth',
  YearToDate = 'YearToDate',
  OneYear = 'OneYear',
}

export const enum StatusOption {
  All = 'All',
  Initiation = 'Initiation',
  Technology = 'Technology',
  Manufacturing = 'Manufacturing',
  Operations = 'Operations',
  Canceled = 'Canceled',
}
