import { routeName as projectRoute } from '../pages/Project';
import DOMPurify from 'dompurify';

const allowedRoutes = {
  project: { url: `/${projectRoute}/%project-id%`, identifier: '%project-id%', regex: /^[0-9]+$/ },
};

export const isValidId = (id: string): boolean => {
  return /^[0-9]+$/.test(id);
};

export const sanitizeString = (input: string): string => {
  return DOMPurify.sanitize(input);
};

export const sanitizeDeepLink = (deepLinkRoute: string) => {
  if (!deepLinkRoute) return '';
  let constructedUrl = '';
  const deepLinkValues = deepLinkRoute.split('/');
  const deepLinkKey = deepLinkValues.length > 1 ? deepLinkValues[1] : '';

  if (deepLinkKey in allowedRoutes) {
    if (deepLinkValues.length > 2) {
      let id = deepLinkValues[2];
      const allowedRoute = allowedRoutes[deepLinkKey as keyof typeof allowedRoutes];
      if (allowedRoute.regex.test(id)) {
        constructedUrl = allowedRoute.url.replace(allowedRoute.identifier, id);
      }
    }
  }

  return sanitizeString(constructedUrl);
};

export const constructProjectIdRoute = (projectId: string = '') => {
  const sanitizedProjectId = sanitizeString(projectId);

  if (isValidId(sanitizedProjectId)) {
    return allowedRoutes.project.url.replace(allowedRoutes.project.identifier, sanitizedProjectId);
  }

  return '';
};
