import User from '../../../models/user';
import { createSlice } from '@reduxjs/toolkit';

export interface CurrentUserState {
  value: User | null;
}

const initialState: CurrentUserState = {
  value: null,
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
