import ModelBase from './base';

export interface ISearchResult {
  notificationCount: number;
  stage: string;
  projectName: string;
  quoteId: string | null;
  fieldSo: string | null;
  manufacturingSo: string | null;
  projectId: string;
}

export default class SearchResult extends ModelBase {
  notificationCount: number;
  stage: string;
  projectName: string;
  quoteId: string | null;
  fieldSo: string | null;
  manufacturingSo: string | null;
  projectId: string;

  constructor({
    notificationCount,
    stage,
    projectName,
    quoteId,
    fieldSo,
    manufacturingSo,
    projectId,
  }: ISearchResult) {
    super();
    this.notificationCount = notificationCount;
    this.stage = stage;
    this.projectName = projectName;
    this.quoteId = quoteId;
    this.fieldSo = fieldSo;
    this.manufacturingSo = manufacturingSo;
    this.projectId = projectId;
  }

  static fromJsonToReduxJson(json: ISearchResult): any {
    const searchResult = new SearchResult({
      notificationCount: json.notificationCount,
      stage: json.stage,
      projectName: json.projectName,
      quoteId: json.quoteId,
      fieldSo: json.fieldSo,
      manufacturingSo: json.manufacturingSo,
      projectId: json.projectId,
    });
    return searchResult.toJson();
  }
}
