import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import NotificationBell from '../../../assets/icons/notification_bell.svg';
import { useAppDispatch, useAppSelector } from '../../../features/state/hooks';
import { useEffect } from 'react';
import {
  setRefreshSearch,
  setSearchCurrentPage,
  setSkip,
} from '../../../features/state/slices/searchSlice';
import { useNavigate } from 'react-router-dom';

const maxPagesToShow = 4;

export const SearchResults = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search, searchResult, loading, showResults, searchCurrentPage } = useAppSelector(
    (state) => state.search.value,
  );
  const pages = Math.ceil(searchResult.totalResults / search.amount);

  useEffect(() => {
    if (search.skip === 0) {
      dispatch(setSearchCurrentPage(1));
    }
  }, [search.skip, dispatch]);

  const updateCurrentPage = (newPage: number) => {
    dispatch(setSearchCurrentPage(newPage));
    dispatch(setSkip((newPage - 1) * search.amount));
    dispatch(setRefreshSearch(true));
  };

  const paginationOptions = (): JSX.Element[] => {
    // Calculate start and end page numbers for current window
    let startPage = Math.max(1, searchCurrentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(pages, startPage + maxPagesToShow - 1);

    // Adjust startPage if we're near the end of the pages
    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const pagesToShow = [];

    // Show previous button if there are more pages to show
    if (startPage > 1) {
      pagesToShow.push(
        <div
          key="previous"
          className="pagination-option previous"
          onClick={() => updateCurrentPage(startPage - 1)}>
          &lt;&lt; Prev
        </div>,
      );
    }

    // Generate pagination page numbers
    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(
        <div
          key={i}
          className={`pagination-option ${i === searchCurrentPage ? 'selected' : ''}`}
          onClick={() => updateCurrentPage(i)}>
          {i}
        </div>,
      );
    }

    // Show next button if there are more pages to show
    if (endPage < pages) {
      pagesToShow.push(
        <div
          key="next"
          className="pagination-option next"
          onClick={() => updateCurrentPage(endPage + 1)}>
          Next &gt;&gt;
        </div>,
      );
    }

    return pagesToShow;
  };

  if (!showResults || loading) {
    if (loading) {
      return (
        <div className="loading-container">
          <div className="loader" />
        </div>
      );
    }
    return <></>;
  }

  if (searchResult.projects.length === 0) {
    return (
      <div className="no-results-container">
        <div className="no-results-content">
          <div className="no-results-text">No results found</div>
        </div>
      </div>
    );
  }

  return (
    <OverlayScrollbarsComponent defer>
      <div className="results-container">
        <div className="results-content">
          <div className="header">
            <div className="results-row">
              <div className="notification">
                <img alt="notification-icon" src={NotificationBell} />
              </div>
              <div className="stage">Stage</div>
              <div className="project-name">Project name</div>
              <div className="quote-id">Quote ID</div>
              <div className="field-so">Field SO #</div>
              <div className="mfg-so">Manufacturing SO #</div>
            </div>
          </div>
          <div className="content">
            {searchResult.projects.map((project) => (
              <>
                <hr />
                <div className="results-row">
                  <div className="notification count">
                    {project.notificationCount > 0 && <span>{project.notificationCount}</span>}
                  </div>
                  <div className={`stage tag ${project.stage.toLowerCase()}`}>
                    {project.stage.toUpperCase()}
                  </div>
                  <div
                    className="project-name"
                    onClick={() =>
                      navigate(`/project/${project.projectId}`, {
                        state: { origin: 'search' },
                      })
                    }>
                    {project.projectName}
                  </div>
                  <div className="quote-id">{project.quoteId}</div>
                  <div className="field-so">{project.fieldSo}</div>
                  <div className="mfg-so">{project.manufacturingSo}</div>
                </div>
              </>
            ))}
          </div>
          {searchResult.totalResults > 0 && (
            <>
              <hr />
              <div className="pagination">{paginationOptions()}</div>
            </>
          )}
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};
