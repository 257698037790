/* eslint-disable no-console */
import { useEffect } from 'react';
import { PageLayout } from './components/common/PageLayout';
import { AuthPageRouter } from './components/auth/AuthPageRouter';
import { AppRoutes } from './AppRoutes';

import './styles/main.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'overlayscrollbars/overlayscrollbars.css';
import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useBackPressed as initializeUseBackPressed } from './utils/hooks/useBackPressed';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

ChartJS.register(ArcElement, Tooltip, Legend);

const App = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  initializeUseBackPressed();

  useEffect(() => {
    const isRootOrLogin = matchPath(pathname, '/') || matchPath(pathname, '/login');
    if (authState?.isAuthenticated && isRootOrLogin) {
      console.log('App: Redirecting to home, authenticated, path /');
      navigate('/home');
    }
  }, [authState, navigate, pathname]);

  return (
    <div className="App">
      <PageLayout>
        <AuthPageRouter AppRoutes={AppRoutes} />
      </PageLayout>
    </div>
  );
};

export default App;
