import React, { useState } from 'react';
import AccordionItem from '../../common/AccordionItem';
import BannerProgressItemHeader from '../../common/BannerProgressItemHeader';
import StepCheckMark from '../../../assets/icons/step_check_mark.svg';
import StatusCancelled from '../../../assets/icons/status_cancelled.svg';
import MilestoneItem from '../../common/MilestoneItem';
import { COMPLETION_DATE_STATUS, MILESTONE_STATUS } from '../../../constants/salesOrder';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import {
  CompletionDateStatus,
  ProjectStage as ProjectStageType,
  ProjectStageChartItem,
} from '../../../types/ProjectTypes';

interface ProjectStageProps {
  content: ProjectStageType;
  goToSummary: (soNumber: string) => void;
}

const ProjectStage = ({ content, goToSummary }: ProjectStageProps) => {
  const onClickIfLink = (link: boolean, soNumber: string) => {
    if (link) goToSummary(soNumber);
  };

  const renderStatusIcon = (status: CompletionDateStatus) => {
    const notStarted = status === COMPLETION_DATE_STATUS.TBD;

    if (notStarted) {
      return <span className={'status-icon circle not-started'} />;
    } else if (status === COMPLETION_DATE_STATUS.CANCELLED) {
      return <img className={'status-icon'} src={StatusCancelled} alt="Status icon" />;
    } else if (status === COMPLETION_DATE_STATUS.IN_PROGRESS) {
      return <span className={'status-icon circle in-progress'} />;
    }

    return <img className={'status-icon'} src={StepCheckMark} alt="Status icon" />;
  };

  return (
    <OverlayScrollbarsComponent defer className="project-stage-container-scrollbar">
      <div className="project-stage-container">
        <div className="project-stage-titles">
          {[{ title: 'Status', size: 5 }].concat(content.itemHeaders!).map((item, idx) => (
            <div key={idx} className={`project-stage-title size-${item.size}`}>
              {idx === 0 ? (
                <div className="status-icon-container">{''}</div>
              ) : (
                <div className="project-stage-text">{item.title}</div>
              )}
            </div>
          ))}
        </div>
        <hr />
        <div className="project-stage-values">
          {content.itemValues!.map((_valueRow, itemIdx) => {
            // Add a blank value to the beginning of the row to account for the status icon, get all values from stage item
            const valueRow = [''].concat(Object.values(_valueRow)).map((value) => value || '');
            return (
              <React.Fragment key={itemIdx}>
                <div key={itemIdx} className="project-stage-row">
                  {valueRow.map((value, idx) => {
                    let jobStageValueClasses = `project-stage-value size-${
                      content.itemHeaders![idx - 1]?.size || 5
                    }`;
                    idx === 0 && (jobStageValueClasses += ' center');
                    content.itemHeaders![idx - 1]?.boldValue && (jobStageValueClasses += ' bold');
                    content.itemHeaders![idx - 1]?.link && (jobStageValueClasses += ' link');

                    return (
                      <div key={idx} className={jobStageValueClasses}>
                        {idx === 0 ? (
                          <div className="status-icon-container">
                            {renderStatusIcon(content.rowProgress!(_valueRow))}
                          </div>
                        ) : (
                          <div
                            className="project-stage-text"
                            onClick={() =>
                              onClickIfLink(content.itemHeaders![idx - 1]?.link || false, value)
                            }>
                            {value}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                {itemIdx !== content.itemValues!.length - 1 && content.itemValues!.length > 1 ? (
                  <hr />
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};

interface JobStepsProps {
  stages: ProjectStageType[];
  chartItems: ProjectStageChartItem[];
  goToSummary: (soNumber: string) => void;
}

const ProjectStages = ({ stages, chartItems, goToSummary }: JobStepsProps) => {
  const [expandedSteps, setExpandedSteps] = useState(stages.map(() => false));

  const setExpandedStep = (idx: number, expanded: boolean) => {
    const newExpandedSteps = [...expandedSteps];
    newExpandedSteps[idx] = expanded;
    setExpandedSteps(newExpandedSteps);
  };

  return (
    <>
      {stages.map((stage, idx: number) => {
        const total = chartItems.find((item) => item.id === stage.id)?.total || 0;

        const displayStage = stage.isMilestone
          ? stage.milestones!.find((i) => i.completionDate !== MILESTONE_STATUS.NotStarted)
          : total > 0;

        if (!displayStage) return null;

        return stage.isMilestone ? (
          <MilestoneItem key={idx} item={stage} />
        ) : (
          <AccordionItem
            key={idx}
            headerElement={
              <BannerProgressItemHeader
                step={stage}
                percentageCompleted={stage.percentageCompleted || 0}
                onClickExpand={() => setExpandedStep(idx, !expandedSteps[idx])}
                expanded={expandedSteps[idx]}
              />
            }
            contentElement={<ProjectStage content={stage} goToSummary={goToSummary} />}
            contentExpanded={expandedSteps[idx]}
          />
        );
      })}
    </>
  );
};

export default ProjectStages;
