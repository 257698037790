/* eslint-disable no-console */
import { useOktaAuth } from '@okta/okta-react';

import OktaSignIn, { EventContext, EventErrorContext } from '@okta/okta-signin-widget';
import { oktaClientId, oktaIssuer } from '../../globalConfig';
import React, {
  Dispatch,
  createContext,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import HalLogo from '../../assets/images/hal-logo.png';

import { OktaAuth as OktaAuthConfig } from '@okta/okta-auth-js';

export const oktaAuth = new OktaAuthConfig({
  issuer: oktaIssuer,
  pkce: true,
  clientId: oktaClientId,
  redirectUri: window.location.origin + '/login',
  postLogoutRedirectUri: window.location.origin + '/logout',
  scopes: ['openid', 'profile', 'email', 'address', 'phone', 'offline_access'],
  tokenManager: {
    autoRenew: true,
  },
  storageManager: {
    token: {
      storageTypes: ['cookie', 'localStorage'],
      useSeparateCookies: true,
    },
  },
  cookies: {
    secure: true,
  },
});

const oktaBaseURL = new URL(oktaIssuer).origin;

export const oktaSignInInstance = new OktaSignIn({
  logo: HalLogo,
  logoText: 'Halliburton',
  brandName: 'Halliburton',
  authClient: oktaAuth,
  colors: {
    brand: '#CC0100',
  },
  baseUrl: oktaBaseURL,
  issuer: oktaIssuer,
  clientId: oktaClientId,
  redirectUri: window.location.origin + '/login',
  useClassicEngine: false,
  language: 'en',
  features: {
    registration: false,
    multiOptionalFactorEnroll: true,
    mfa: true,
  },
  idpDiscovery: {
    requestContext: '/login',
  },
  helpLinks: {
    custom: [
      {
        text: 'For assistance, contact the Halliburton IT Service Center',
        href: 'mailto:globalITSC@halliburton.com',
      },
    ],
  },
});

oktaSignInInstance.on('afterError', (context: EventContext, error?: EventErrorContext) => {
  console.error(
    `Okta Sign In Widget Error\nName: ${error}\nMessage: ${error?.message}\nStatus Code: ${error?.statusCode}\nController: ${context.controller}`,
  );
});

interface OktaAuthContextType {
  isOktaModalOpen: boolean;
  redirectPath: string;
  closeOktaModal: any;
  openOktaModal: any;
  restoreOriginalUri: (originalUri: string) => void;
  setRedirectPath: Dispatch<SetStateAction<string>>;
}

const OktaAuthContext = createContext<OktaAuthContextType>({
  isOktaModalOpen: false,
  redirectPath: '',
  closeOktaModal: () => {},
  openOktaModal: () => {},
  restoreOriginalUri: () => {},
  setRedirectPath: () => {},
});

export function OktaAuthProvider({ children }: { children: React.ReactNode }) {
  const [isOktaModalOpen, setIsOpen] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/');
  const navigate = useNavigate();

  const restoreOriginalUri = async (originalUri: string) => {
    navigate(originalUri || '/', { replace: true });
  };

  const closeOktaModal = () => {
    oktaSignInInstance.remove();
    setIsOpen(false);
  };
  const openOktaModal = (redirectPath: string) => {
    if (redirectPath) {
      setRedirectPath(redirectPath);
    } else {
      setRedirectPath('/');
    }

    if (!isOktaModalOpen) {
      setIsOpen(true);
    }
    oktaSignInInstance.show();
  };

  const contextValue = {
    isOktaModalOpen,
    redirectPath,
    closeOktaModal,
    openOktaModal,
    restoreOriginalUri,
    setRedirectPath,
  };

  return <OktaAuthContext.Provider value={contextValue}>{children}</OktaAuthContext.Provider>;
}

export function useOktaAuthContext() {
  return useContext(OktaAuthContext);
}

export const OktaAuthInfo = () => {
  const { authState } = useOktaAuth();
  const { openOktaModal } = useOktaAuthContext();
  const [userInfo, setUserInfo] = useState<{ name?: string }>();

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.token
        .getUserInfo()
        .then((info) => {
          setUserInfo(info);
        })
        .catch((error) => {
          console.error('Error getting user info', error);
        });
    }
  }, [authState]);

  const login = () => {
    openOktaModal();
  };

  const logout = async () => oktaAuth.signOut();

  if (!authState) {
    return <div>Loading...</div>;
  }

  if (!authState.isAuthenticated) {
    return (
      <div>
        <p>Not Logged in yet</p>
      </div>
    );
  }

  return (
    <div>
      <p>Logged in!</p>
      <p>{userInfo?.name}</p>
      <p>Token: {authState.accessToken?.accessToken}</p>
      <p>Id Token: {authState.idToken?.idToken}</p>
      <button onClick={login}>Reauth</button>
      <button onClick={logout}>Logout</button>
    </div>
  );
};
