import { CategorySearch } from '../components/pages/Search/CategorySearch';
import { SearchResults } from '../components/pages/Search/SearchResults';

export const routeName = 'search';

const Search = () => {
  return (
    <div className="search-container">
      <CategorySearch />

      <SearchResults />
    </div>
  );
};

export default Search;
