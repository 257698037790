import React from 'react';

interface AccordionItemProps {
  headerElement: React.ReactElement;
  contentElement: React.ReactElement;
  contentExpanded: boolean;
  isDarkened?: boolean;
}

const AccordionItem = ({
  headerElement,
  contentElement,
  contentExpanded,
  isDarkened = false,
}: AccordionItemProps) => {
  // Conditional class name or style
  const containerClass = `accordion-item-container ${isDarkened ? 'darkened' : ''}`;

  return (
    <div className={containerClass}>
      <div className="accordion-item-header">{headerElement}</div>
      <div className={`accordion-item-content ${contentExpanded ? 'expanded' : ''}`}>
        {contentElement}
      </div>
    </div>
  );
};

export default AccordionItem;
