import { ApiBase, ApiResponse } from '../apiBase';

/**
 * Retrieve user notifications.
 */
export const getNotifications = (
  apiInstance: ApiBase,
  projectId: string | null,
): Promise<ApiResponse> => {
  return apiInstance.get(`Notifications?projectId=${projectId || ''}`);
};

export const toggleNotificationPin = (
  apiInstance: ApiBase,
  notificationId: string,
  userId: string,
): Promise<ApiResponse> => {
  return apiInstance.post(`Notifications/${notificationId}/User/${userId}/Pin`);
};

export const toggleNotificationAcknowledge = (
  apiInstance: ApiBase,
  notificationId: string,
  userId: string,
): Promise<ApiResponse> => {
  return apiInstance.post(`Notifications/${notificationId}/User/${userId}/Acknowledge`);
};
