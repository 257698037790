import React, { useEffect, useState } from 'react';
import PropsType from 'prop-types';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import PinnedIcon from '../../assets/icons/pinned_icon.svg';
import UnpinnedIcon from '../../assets/icons/unpinned_icon.svg';
import AttachmentIcon from '../../assets/icons/paper_clip_icon.svg';
import ApprovedIcon from '../../assets/icons/approved.svg';
import LateIcon from '../../assets/icons/late.svg';
import InfoIcon from '../../assets/icons/info_grey.svg';
import SelectedLeftBorder from '../../assets/icons/selected_left_border.svg';
import { getAlphabetPosition, getInitials } from '../../utils/naming';
import { STATUS_TYPES } from '../../constants/notifications';
import { getNotifications, toggleNotificationPin } from '../../api/endpoints/notifications';
import {
  setIsPinned,
  setNotifications,
  setProjectNotifications,
} from '../../features/state/slices/notificationsSlice';
import { useNavigate } from 'react-router-dom';
import { getUsers } from '../../api/endpoints/users';
import Notification from '../../models/notification';
import { useApi } from '../../api/ApiContext';
import { useAppDispatch, useAppSelector } from '../../features/state/hooks';

const tabs = ['Unread', 'All'];

const filters = ['All', 'Users', 'App'];

const sortOptions = ['Date', 'Read Status'];

const TABS = {
  UNREAD: 0,
  ALL: 1,
};

const DEFAULT_FILTERS = {
  TYPE: 'All',
  SORT: 'Date',
};

const NotificationsList = (props) => {
  const { apiInstance } = useApi();
  const navigate = useNavigate();
  const [selectedTabIdx, setSelectedTabIdx] = useState(TABS.UNREAD);
  const [selectedFilter, setSelectedFilter] = useState(DEFAULT_FILTERS.TYPE);
  const [selectedSortOption, setSelectedSortOption] = useState(DEFAULT_FILTERS.SORT);

  const { all: allNotifications, project: projectNotifications } = useAppSelector(
    (state) => state.notifications.value,
  );
  const notifications = props.projectId ? projectNotifications : allNotifications;
  const currentUser = useAppSelector((state) => state.currentUser.value);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const parsedNotifications = await getNotifications(apiInstance, props.projectId)
        .then((response) =>
          Notification.fromJsonArrayToObjectReduxJsonArray(response.responseAsJson),
        )
        .catch((error) => {});

      if (parsedNotifications === undefined) return;

      const userIds = parsedNotifications.reduce((uIds, notification) => {
        if (notification.isUserMessage && !uIds.includes(notification.sourceId))
          uIds.push(notification.sourceId);
        return uIds;
      }, []);

      let notificationUsers = await getUsers(apiInstance, userIds)
        .then((response) => response.responseAsJson)
        .catch((error) => {});

      if (notificationUsers) {
        parsedNotifications.forEach((notification) => {
          const user = notificationUsers.find((user) => user.id === notification.sourceId);
          if (user) notification.userName = user.name;
        });
      }

      if (props.projectId) dispatch(setProjectNotifications(parsedNotifications));
      else dispatch(setNotifications(parsedNotifications));
    })();
  }, [apiInstance, dispatch, props.projectId]);

  const onNotificationClick = (target, notificationId) => {
    if (target.closest('.pin-btn')) return;
    props.onNotificationClick(notificationId);
  };

  const getFilteredNotifications = () => {
    let _notifications =
      selectedTabIdx === TABS.ALL
        ? notifications
        : notifications.filter((notification) => !notification.isRead);

    _notifications = applyFilterAndSort(_notifications, selectedFilter, selectedSortOption);

    return _notifications;
  };

  const setSelectedTab = (idx) => {
    setSelectedTabIdx(idx);
    switch (idx) {
      case TABS.UNREAD:
        setSelectedFilter(DEFAULT_FILTERS.TYPE);
        setSelectedSortOption(DEFAULT_FILTERS.SORT);
        break;
      case TABS.ALL:
      default:
        break;
    }
  };

  const applyFilterAndSort = (notifications, filter, sort) => {
    const filteredNotifications = _filterNotifications([...notifications], filter);
    const sortedNotifications = _sortNotifications(filteredNotifications, sort);
    return sortedNotifications;
  };

  const _filterNotifications = (notifications, filter) => {
    switch (filter) {
      case 'Users':
        return notifications.filter((notification) => notification.isUserMessage);
      case 'App':
        return notifications.filter((notification) => !notification.isUserMessage);
      case 'All':
      default:
        return notifications;
    }
  };

  const _sortNotifications = (notifications, sortOption) => {
    switch (sortOption) {
      case 'Date':
        return notifications.sort((a, b) => {
          const dateA = new Date(JSON.parse(a.timeAsJson));
          const dateB = new Date(JSON.parse(b.timeAsJson));
          return dateB - dateA;
        });
      case 'Read Status':
      default:
        return notifications.sort((a, b) => {
          const readStatusA = a.isRead;
          const readStatusB = b.isRead;
          return readStatusA - readStatusB;
        });
    }
  };

  const togglePin = async (notificationId) => {
    //TODO: Remove when test notifications are removed
    const isTestNotification = !isNaN(notificationId);
    if (isTestNotification) {
      const isPinned = !notifications.find((n) => n.id === notificationId).isPinned;
      dispatch(setIsPinned({ notificationId, isPinned }));
      return;
    }

    const isPinned = await toggleNotificationPin(apiInstance, notificationId, currentUser.id).then(
      (resp) => resp.responseAsJson.isPinned,
    );
    dispatch(setIsPinned({ notificationId, isPinned, projectId: props.projectId || '' }));
  };

  return (
    <div className="notifications-list-container">
      <ul>
        {tabs.map((tab, idx) => {
          const activeTabClass = idx === selectedTabIdx ? 'active' : '';
          const allCount = notifications.length;
          const unreadCount = notifications.filter((notification) => !notification.isRead).length;
          const count = idx === 0 ? unreadCount : allCount;

          return (
            <li key={idx} className={activeTabClass} onClick={() => setSelectedTab(idx)}>
              <span className="title">{tab}</span> <span className="count">{count >= 200 ? '200 +' : count}</span>
            </li>
          );
        })}
      </ul>
      {selectedTabIdx === TABS.ALL && (
        <div className="filters">
          <div className="filter">
            <span>Notification Type</span>
            <select
              name="type"
              id="type"
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}>
              {filters.map((filter, idx) => (
                <option key={idx} value={filter}>
                  {filter}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <span>Sort By</span>
            <select
              name="type"
              id="type"
              value={selectedSortOption}
              onChange={(e) => setSelectedSortOption(e.target.value)}>
              {sortOptions.map((sortOption, idx) => (
                <option key={idx} value={sortOption}>
                  {sortOption}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <OverlayScrollbarsComponent defer>
        <div className="notifications">
          {getFilteredNotifications()
            .sort((notification) => (notification.isPinned ? -1 : 1))
            .map((notification, idx) => {
              const {
                id,
                isPinned,
                isUserMessage,
                isRead,
                hasAttachment,
                userName,
                timeStamp,
                description,
                taskNameLink,
                status,
              } = notification;

              const alphabetClass = `alphabet-${getAlphabetPosition(userName.charAt(0))}`;
              const statusType =
                status !== STATUS_TYPES.NONE
                  ? status === STATUS_TYPES.APPROVED
                    ? 'approved'
                    : 'late'
                  : '';
              const statusIcon =
                status !== STATUS_TYPES.NONE
                  ? status === STATUS_TYPES.APPROVED
                    ? ApprovedIcon
                    : LateIcon
                  : InfoIcon;

              const selectedNotificationClass =
                id === props.selectedNotificationId ? 'selected' : '';

              const validTaskNameLink = !props.projectId && taskNameLink !== '/project/'; // Empty link

              return (
                <div key={idx}>
                  {idx !== 0 && <div className="separator" />}
                  <div
                    key={idx}
                    className={`notification ${selectedNotificationClass}`}
                    onClick={(e) => onNotificationClick(e.target, id)}>
                    <div className="hint-container">
                      {id === props.selectedNotificationId && (
                        <img src={SelectedLeftBorder} alt="Selected hint" />
                      )}
                    </div>
                    <div className="notification-content">
                      <div className="notification-header">
                        <div className="sender">
                          <div className={`sender-image ${alphabetClass} ${statusType}`}>
                            {isUserMessage ? (
                              <span className="initials">{getInitials(userName)}</span>
                            ) : (
                              <img className="nonuser-icon" src={statusIcon} alt="Sender" />
                            )}
                          </div>
                          <div className="sender-details">
                            <span className="name">{userName}</span>
                            <span className="time">{timeStamp}</span>
                          </div>
                        </div>
                        <div className="status-icons">
                          {statusType && (
                            <span className={`status ${statusType}`}>{statusType}</span>
                          )}
                          <div className="icons">
                            {hasAttachment && <img src={AttachmentIcon} alt="Attachment" />}
                            <button className="pin-btn" onClick={() => togglePin(id)}>
                              <img
                                id="pin"
                                src={isPinned ? PinnedIcon : UnpinnedIcon}
                                alt="Pinned"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="notification-body-container">
                        <div className="notification-body">
                          <p className={!isRead ? 'unread' : ''}>{description}</p>
                        </div>
                        <div className="notification-footer">
                          {validTaskNameLink && (
                            <span onClick={() => navigate(taskNameLink)}>View Project</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
};

NotificationsList.propTypes = {
  projectId: PropsType.string,
  selectedNotificationId: PropsType.string,
  onNotificationClick: PropsType.func.isRequired,
};

export default NotificationsList;
