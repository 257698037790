import ModelBase from './base';

interface IUser {
  id: string;
  email: string;
  initials: string;
  name: string;
}

export default class User extends ModelBase {
  id: string;
  email: string;
  initials: string;
  name: string;

  constructor({ id, email, initials, name }: IUser) {
    super();
    this.id = id;
    this.email = email;
    this.initials = initials;
    this.name = name;
  }

  static fromJsonToReduxJson(json: any): any {
    const user = new User({
      id: json.id,
      email: json.email,
      initials: json.initials,
      name: json.name,
    });
    return user.toJson();
  }
}
